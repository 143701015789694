var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-app', {
    style: {
      backgroundColor: _vm.backgroundColor
    },
    attrs: {
      "id": "inspire"
    }
  }, [!_vm.$vuetify.breakpoint.mobile ? _c('v-app-bar', {
    directives: [{
      name: "intersect",
      rawName: "v-intersect",
      value: {
        handler: _vm.onScroll,
        options: {
          threshold: [0, 1.0]
        }
      },
      expression: "{ handler: onScroll, options: { threshold: [0, 1.0] } }"
    }],
    staticClass: "header",
    attrs: {
      "app": "",
      "height": "85",
      "absolute": "",
      "elevation": "0",
      "color": "white"
    }
  }, [_c('v-responsive', {
    staticClass: "ma-0",
    attrs: {
      "width": "100vw",
      "height": "100%"
    }
  }, [_c('v-responsive', {
    staticClass: "mx-auto",
    attrs: {
      "width": "100%",
      "max-width": _vm.maxWidth,
      "height": "100%"
    }
  }, [_c('v-layout', {
    staticClass: "fill-height",
    attrs: {
      "justify-center": "",
      "align-center": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "shrink": ""
    }
  }, [_c('router-link', {
    attrs: {
      "to": "/"
    }
  }, [_c('img', {
    attrs: {
      "src": "/images/timemall/ci.png"
    }
  })])], 1), _c('v-spacer'), _c('v-flex', {
    attrs: {
      "shrink": ""
    }
  }, [_c('v-layout', {
    staticClass: "pl-4",
    attrs: {
      "slot": "center"
    },
    slot: "center"
  }, [_c('v-btn', {
    attrs: {
      "width": "150",
      "height": "85",
      "dark": "",
      "text": "",
      "tile": "",
      "x-large": "",
      "color": "black",
      "active-class": "no-active",
      "ripple": false,
      "to": "/gshop/giftcards"
    }
  }, [_vm._v("모바일상품권")]), _c('v-btn', {
    attrs: {
      "width": "150",
      "height": "85",
      "dark": "",
      "text": "",
      "tile": "",
      "x-large": "",
      "color": "black",
      "active-class": "no-active",
      "ripple": false,
      "to": "/about"
    }
  }, [_vm._v("회사소개")]), _c('v-btn', {
    attrs: {
      "width": "150",
      "height": "85",
      "dark": "",
      "text": "",
      "tile": "",
      "x-large": "",
      "color": "black",
      "active-class": "no-active",
      "ripple": false,
      "to": "/center/faqs"
    }
  }, [_vm._v("자주묻는질문")]), _c('v-btn', {
    attrs: {
      "width": "150",
      "height": "85",
      "dark": "",
      "text": "",
      "tile": "",
      "x-large": "",
      "color": "black",
      "active-class": "no-active",
      "ripple": false,
      "to": "/center/notifications"
    }
  }, [_vm._v("공지사항")]), _c('v-btn', {
    attrs: {
      "width": "150",
      "height": "85",
      "dark": "",
      "text": "",
      "tile": "",
      "x-large": "",
      "color": "black",
      "active-class": "no-active",
      "ripple": false,
      "to": "/board/question"
    }
  }, [_vm._v("후기 및 문의")])], 1)], 1), _vm.siteInfo.memberPayable ? [_c('v-spacer'), _vm.logon ? _c('v-flex', {
    attrs: {
      "shrink": ""
    }
  }, [_c('v-layout', {
    staticClass: "pl-4",
    attrs: {
      "slot": "center"
    },
    slot: "center"
  }, [_c('v-btn', {
    attrs: {
      "height": "85",
      "dark": "",
      "text": "",
      "large": "",
      "tile": "",
      "color": "primary",
      "active-class": "no-active",
      "ripple": false
    },
    on: {
      "click": _vm.logout
    }
  }, [_vm._v("로그아웃")]), _c('v-btn', {
    attrs: {
      "height": "85",
      "dark": "",
      "text": "",
      "large": "",
      "tile": "",
      "color": "primary",
      "active-class": "no-active",
      "ripple": false,
      "to": "/member/profile"
    }
  }, [_vm._v("마이페이지")])], 1)], 1) : _c('v-flex', {
    attrs: {
      "shrink": ""
    }
  }, [_c('v-layout', {
    staticClass: "pl-4",
    attrs: {
      "slot": "center"
    },
    slot: "center"
  }, [_c('v-btn', {
    attrs: {
      "height": "85",
      "dark": "",
      "text": "",
      "large": "",
      "tile": "",
      "color": "primary",
      "active-class": "no-active",
      "ripple": false,
      "to": "/login"
    }
  }, [_vm._v("로그인")]), _c('v-btn', {
    attrs: {
      "height": "85",
      "dark": "",
      "text": "",
      "large": "",
      "tile": "",
      "color": "primary",
      "active-class": "no-active",
      "ripple": false,
      "to": "/join"
    }
  }, [_vm._v("회원가입")])], 1)], 1)] : _vm._e()], 2)], 1)], 1)], 1) : _vm._e(), _vm.$vuetify.breakpoint.mobile ? _c('v-app-bar', {
    directives: [{
      name: "intersect",
      rawName: "v-intersect",
      value: {
        handler: _vm.onScroll,
        options: {
          threshold: [0, 1.0]
        }
      },
      expression: "{ handler: onScroll, options: { threshold: [0, 1.0] } }"
    }],
    attrs: {
      "height": "60",
      "max-height": "60",
      "color": "white",
      "elevation": "2"
    }
  }, [_c('v-layout', {
    attrs: {
      "wrap": ""
    }
  }, [_c('v-responsive', {
    attrs: {
      "width": "100%",
      "height": "60"
    }
  }, [_c('v-layout', {
    attrs: {
      "align-center": "",
      "fill-height": ""
    }
  }, [_c('v-app-bar-nav-icon', {
    on: {
      "click": function ($event) {
        _vm.drawer = true;
      }
    }
  }), _c('v-spacer'), _c('router-link', {
    staticClass: "align-self-center",
    attrs: {
      "to": "/"
    }
  }, [_c('img', {
    staticStyle: {
      "display": "block"
    },
    attrs: {
      "src": "/images/timemall/ci.png",
      "width": "75",
      "height": "auto"
    }
  })]), _c('v-spacer'), _c('v-sheet', {
    attrs: {
      "width": "48"
    }
  })], 1)], 1)], 1)], 1) : _vm._e(), _c('v-navigation-drawer', {
    attrs: {
      "app": "",
      "fixed": "",
      "temporary": "",
      "height": "auto",
      "width": "100%"
    },
    model: {
      value: _vm.drawer,
      callback: function ($$v) {
        _vm.drawer = $$v;
      },
      expression: "drawer"
    }
  }, [_c('v-list', {
    staticClass: "overflow-auto pa-0",
    attrs: {
      "max-height": "100vh"
    }
  }, [_c('v-list-item', {
    staticClass: "primary",
    attrs: {
      "dark": ""
    }
  }, [_c('v-list-item-title', [_c('span', [_vm._v("타임몰")])]), _c('v-list-item-action', {
    on: {
      "click": function ($event) {
        _vm.drawer = false;
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-close")])], 1)], 1), _c('v-list-item', {
    staticClass: "white",
    attrs: {
      "active-class": "no-active",
      "to": "/about"
    }
  }, [_c('v-list-item-title', [_c('b', [_vm._v("회사소개")])])], 1), _c('v-divider'), _c('v-list-item', {
    staticClass: "white",
    attrs: {
      "active-class": "no-active",
      "to": "/board/question"
    }
  }, [_c('v-list-item-title', [_c('b', [_vm._v("문의하기")])])], 1), _c('v-divider'), _c('v-list-group', {
    staticClass: "white"
  }, [_c('v-list-item-title', {
    attrs: {
      "slot": "activator"
    },
    slot: "activator"
  }, [_c('b', [_vm._v("고객센터")])]), _c('v-divider'), _c('v-list-item-group', [_c('v-list-item', {
    attrs: {
      "active-class": "white secondary--text",
      "to": "/center/notifications"
    }
  }, [_c('v-list-item-title', [_vm._v("공지사항")])], 1), _c('v-list-item', {
    attrs: {
      "active-class": "white secondary--text",
      "to": "/center/faqs"
    }
  }, [_c('v-list-item-title', [_vm._v("자주묻는질문")])], 1)], 1)], 1), _c('v-divider'), _c('v-list-item', {
    staticClass: "white",
    attrs: {
      "active-class": "no-active",
      "to": "/gshop/giftcards"
    }
  }, [_c('v-list-item-title', [_c('b', [_vm._v("기프트샵")])])], 1), _c('v-divider'), _vm.siteInfo.memberPayable && _vm.logon ? [_c('v-list-group', {
    staticClass: "white"
  }, [_c('v-list-item-title', {
    attrs: {
      "slot": "activator"
    },
    slot: "activator"
  }, [_c('b', [_vm._v("마이페이지")])]), _c('v-divider'), _c('v-list-item-group', [_c('v-list-item', {
    attrs: {
      "active-class": "no-active",
      "to": "/member/profile"
    }
  }, [_c('v-list-item-title', [_vm._v("회원정보수정")])], 1), _c('v-list-item', {
    attrs: {
      "active-class": "no-active",
      "to": "/member/gshop/orders"
    }
  }, [_c('v-list-item-title', [_vm._v("상품권 구매내역")])], 1)], 1)], 1), _c('v-divider')] : _vm._e(), _c('v-card', {
    staticClass: "pa-5",
    attrs: {
      "elevation": "0"
    }
  }, [_c('v-card-subtitle', {
    staticClass: "pa-0"
  }, [_c('span', {
    staticClass: "black--text"
  }, [_vm._v("대표전화")])]), _c('v-divider', {
    staticClass: "pa-0 my-2"
  }), _c('v-card-title', {
    staticClass: "pa-0"
  }, [_c('span', {
    staticClass: "mt-2 mr-1 subtitle-2 black--text"
  }, [_vm._v("Tel.")]), _c('span', {
    staticClass: "headline primary--text"
  }, [_vm._v("010-4122-0080")])]), _c('v-card-text', {
    staticClass: "pa-0"
  }, [_c('span', {
    staticClass: "caption black--text"
  }, [_vm._v("평일 10:00 - 17:00 / 점심 12:30 - 13:30")])]), _c('v-card-text', {
    staticClass: "pa-0"
  }, [_c('span', {
    staticClass: "caption black--text"
  }, [_vm._v("주말 및 공휴일 휴무")])])], 1), _vm.siteInfo.memberPayable ? [_c('v-divider'), _c('v-list-item', {
    staticClass: "justify-end"
  }, [_vm.logon ? [_c('v-list-item-action', {
    staticClass: "ma-0"
  }, [_c('v-btn', {
    attrs: {
      "plain": ""
    },
    on: {
      "click": _vm.logout
    }
  }, [_c('v-icon', {
    attrs: {
      "left": "",
      "size": "16"
    }
  }, [_vm._v(_vm._s(_vm.mdiLogoutVariant))]), _vm._v("로그아웃")], 1)], 1)] : [_c('v-list-item-action', {
    staticClass: "ma-0"
  }, [_c('v-btn', {
    attrs: {
      "plain": "",
      "active-class": "no-active",
      "to": "/login"
    }
  }, [_c('v-icon', {
    attrs: {
      "left": "",
      "size": "16"
    }
  }, [_vm._v(_vm._s(_vm.mdiLockOutline))]), _vm._v("로그인")], 1)], 1), _c('v-list-item-action', {
    staticClass: "ma-0"
  }, [_c('v-btn', {
    attrs: {
      "plain": "",
      "active-class": "no-active",
      "to": "/join"
    }
  }, [_c('v-icon', {
    attrs: {
      "left": "",
      "size": "16"
    }
  }, [_vm._v(_vm._s(_vm.mdiAccountPlus))]), _vm._v("회원가입")], 1)], 1)]], 2)] : _vm._e()], 2)], 1), _c('v-main', [_c('v-container', {
    staticClass: "pa-0 overflow-visible",
    attrs: {
      "fluid": ""
    }
  }, [_vm._t("default")], 2)], 1), _c('v-divider'), _c('v-responsive', {
    directives: [{
      name: "intersect",
      rawName: "v-intersect",
      value: {
        handler: _vm.onFooter,
        options: {
          threshold: [0, 1.0]
        }
      },
      expression: "{ handler: onFooter, options: { threshold: [0, 1.0] } }"
    }],
    attrs: {
      "min-height": "200"
    }
  }, [_c('v-footer', {
    staticClass: "body-2 overflow-auto footer-serve",
    class: {
      'justify-center': !_vm.$vuetify.breakpoint.mobile
    },
    attrs: {
      "color": "#ffffff",
      "height": "64"
    }
  }, [_c('v-responsive', {
    attrs: {
      "width": "auto",
      "max-width": _vm.maxWidth
    }
  }, [_c('v-row', {
    staticClass: "py-2",
    attrs: {
      "align": "center",
      "no-gutters": ""
    }
  }, [_c('v-col', {
    staticClass: "pr-4",
    attrs: {
      "cols": "auto"
    }
  }, [_c('router-link', {
    attrs: {
      "to": "/terms/tos"
    }
  }, [_vm._v("이용약관")])], 1), _c('v-divider', {
    staticClass: "my-1 grey lighten-2",
    attrs: {
      "vertical": ""
    }
  }), _c('v-col', {
    staticClass: "px-4",
    attrs: {
      "cols": "auto"
    }
  }, [_c('router-link', {
    attrs: {
      "to": "/terms/privacy"
    }
  }, [_vm._v("개인정보취급방침")])], 1)], 1)], 1)], 1), _c('hr', {
    staticClass: "v-divider theme--light",
    attrs: {
      "data-v-3f0bee24": "",
      "role": "separator",
      "aria-orientation": "horizontal"
    }
  }), _c('v-footer', {
    staticClass: "justify-center",
    attrs: {
      "color": "white",
      "min-height": "73"
    }
  }, [_c('v-responsive', {
    staticClass: "py-6",
    class: {
      'pa-4': _vm.$vuetify.breakpoint.mobile
    },
    attrs: {
      "width": "100vw",
      "max-width": _vm.maxWidth
    }
  }, [_c('v-row', [_c('v-col', {
    staticClass: "mr-auto",
    attrs: {
      "cols": "12",
      "sm": "3",
      "lg": "2"
    }
  }, [_c('img', {
    staticStyle: {
      "display": "block"
    },
    attrs: {
      "src": "/images/timemall/ci.png",
      "width": "150",
      "height": "auto",
      "contain": ""
    }
  })]), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "9",
      "lg": "6"
    }
  }, [_c('ul', {
    staticClass: "footer-info"
  }, [_c('li', [_c('b', [_vm._v("타임몰")])]), _c('li', [_c('b', {
    staticClass: "mr-2"
  }, [_vm._v("주소")]), _vm._v("광주광역시 북구 첨단과기로208번길 43-10, a동 13층 5호(오룡동, 마루힐 ibc)")]), _c('li', [_c('b', {
    staticClass: "mr-2"
  }, [_vm._v("대표번호")]), _vm._v("010-4122-0080")]), _c('li', [_c('b', {
    staticClass: "mr-2"
  }, [_vm._v("대표자")]), _vm._v("변성욱")]), _c('li', [_c('b', {
    staticClass: "mr-2"
  }, [_vm._v("대표핸드폰번호")]), _vm._v("010-4122-0080")]), _c('li', [_c('b', {
    staticClass: "mr-2"
  }, [_vm._v("사업자등록번호")]), _vm._v("822-98-01575")]), _c('li', [_c('b', {
    staticClass: "mr-2"
  }, [_vm._v("통신판매업신고")]), _vm._v("제 2023-광주북구-0100호")]), _c('li', [_c('b', {
    staticClass: "mr-2"
  }, [_vm._v("이메일")]), _vm._v("byunpol84@naver.com")])])]), _c('v-col', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.$vuetify.breakpoint.mobile,
      expression: "!$vuetify.breakpoint.mobile"
    }],
    attrs: {
      "lg": "1"
    }
  }, [_c('form', {
    attrs: {
      "name": "KB_AUTHMARK_FORM",
      "method": "get"
    }
  }, [_c('input', {
    attrs: {
      "type": "hidden",
      "name": "page",
      "value": "B009111"
    }
  }), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "cc",
      "value": "b010807:b008491"
    }
  }), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "mHValue",
      "value": "b578d5cf6865db7e76580460d3359966"
    }
  })]), _c('a', {
    attrs: {
      "href": "#",
      "onclick": "javascript:onPopKBAuthMark();return false;"
    }
  }, [_c('img', {
    attrs: {
      "src": "http://img1.kbstar.com/img/escrow/escrowcmark.gif",
      "border": "0"
    }
  })])]), _c('v-col', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.$vuetify.breakpoint.mobile,
      expression: "!$vuetify.breakpoint.mobile"
    }],
    attrs: {
      "lg": "3"
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/brand.png"),
      "width": _vm.$vuetify.breakpoint.mobile ? '230' : '294',
      "height": _vm.$vuetify.breakpoint.mobile ? '80' : '94',
      "contain": ""
    }
  })])], 1)], 1)], 1)], 1), _vm._t("fab", function () {
    return [_c('div', {
      staticClass: "quick-link-btn"
    }, [_c('v-btn', {
      attrs: {
        "href": 'http://qr.kakao.com/talk/pvuBlLraz78iekdCjhrPjriJ2TU-',
        "target": "_blank"
      }
    }, [_c('span', {
      staticClass: "blind"
    }, [_vm._v("카톡상담바로가기")])])], 1)];
  }, {
    "scrollTo": _vm.scrollTo,
    "showScroll": _vm.showScroll
  })], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }